.dropdown {
  background-color: rgb(0, 42, 58) !important;
}

.item {
  color: white !important;
}

.item:hover {
  background-color: rgb(44, 113, 185) !important;
}
